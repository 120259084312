import classNames from 'classnames';
import defaults from 'lodash/defaults';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import { useSpring, animated } from 'react-spring';

import { default as defaultSpring } from 'springs';

const Hr = ({ className = '', springConfig = {}, ...props }) => {
  const ref = useRef();
  const { inViewport } = useInViewport(ref);

  const animStyles = useSpring({
    config: defaults({}, springConfig, defaultSpring),
    from: { width: '0%' },
    to: inViewport && { width: '100%' },
    delay: 200,
  });

  return (
    <div className={classNames('opacity-50', className)}>
      <animated.hr ref={ref} style={animStyles} {...props} />
    </div>
  );
};

Hr.propTypes = {
  springConfig: PropTypes.object,
  className: PropTypes.string,
  props: PropTypes.object,
};

export default Hr;
