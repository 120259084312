import classNames from 'classnames';
import { useStaticQuery, graphql, Link } from 'gatsby';
import React from 'react';

import Container from 'components/container';
import Hr from 'components/hr';
import LinkOrExternalLink from 'components/link-or-external-link';
import Logo from 'components/logo';
import SocialIcon from 'components/social-icon';
import Vr from 'components/vr';
import { useDictionary, useSettings } from 'hooks';
import { toTree, renderTree } from 'utils/menus';

const Footer = () => {
  const { phraseAllRightsReserved } = useDictionary();

  const { offices = [], title = '', description = '' } = useSettings();
  const primaryOffice = offices?.find(({ isPrimary }) => isPrimary);
  const {
    googleMap: {
      stateShort,
      streetName,
      streetNumber,
      city,
      postCode,
      country,
    } = {
      stateShort: '',
      streetName: '',
      streetNumber: '',
      city: '',
      postCode: '',
      country: '',
    },
    groupedContactLinks: { tel = [], mailto = [] } = { tel: [], mailto: [] },
  } = primaryOffice || {};

  const { allWpMenu, allWpPage } = useStaticQuery(
    graphql`
      query {
        allWpPage(
          filter: { slug: { in: ["privacy-policy", "terms-of-use"] } }
        ) {
          nodes {
            title
            uri
          }
        }
        allWpMenu(
          filter: { locations: { in: [FOOTER_NAV, SOCIAL_NAV] } }
          sort: { fields: locations }
        ) {
          nodes {
            locations
            menuItems {
              nodes {
                cssClasses
                title
                target
                uri
                order
                databaseId
                parentDatabaseId
                label
                id
              }
            }
          }
        }
      }
    `
  );

  const [
    footerNav,
    {
      menuItems: { nodes: socialNav },
    },
  ] = allWpMenu.nodes;

  const socialIcons = {
    linkedin: 'LinkedIn',
  };

  const tree = toTree(footerNav?.menuItems?.nodes);

  return (
    <>
      <Hr />
      <footer className='bg-white'>
        <Container className='lg:grid grid-cols-12 gap-gutter'>
          <div className='col-span-3 lg:flex justify-between'>
            <div className='py-page-my'>
              <Logo className='mb-12 w-full max-w-[260px]' width={260} />
              <span className='font-serif font-medium text-md'>
                {description}
              </span>
            </div>
            <Vr className='-lg:hidden ml-gutter' />
          </div>

          <Hr className='-mx-page-mx lg:hidden' />

          <div className='col-span-3 lg:flex justify-between'>
            <div className='py-page-my'>
              <h3 className='mb-4 font-serif font-medium text-sm'>{title}</h3>
              <p className='mb-4 text-sm'>
                {`${streetNumber} ${streetName}`}
                <br />
                {`${city}, ${stateShort} ${postCode}`}
                <br />
                {country}
              </p>
              {tel && (
                <ul className='mb-4 text-sm'>
                  {tel.map(({ target, title, url }, index) => (
                    <li key={`${title}-${index}`}>
                      <a href={url} target={target}>
                        {title}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
              {mailto && (
                <ul className='mb-4 text-sm'>
                  {mailto.map(({ target, title, url }, index) => (
                    <li key={`${title}-${index}`}>
                      <a href={url} target={target}>
                        {title}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
              <span className='text-sm'>
                &copy; {new Date().getFullYear()} {title}.<br />
                {phraseAllRightsReserved}.{' '}
                {allWpPage.nodes.map(({ title, uri }) => (
                  <React.Fragment key={uri}>
                    <Link to={uri}>{title}</Link>.{' '}
                  </React.Fragment>
                ))}
              </span>
              <ul className='flex gap-gutter'>
                {socialNav.map((item, index) => (
                  <li key={`${item.id}-${index}`}>
                    <a
                      aria-label={item.label}
                      href={item.uri}
                      target={item.target}
                    >
                      <SocialIcon
                        className='mt-11'
                        platform={
                          socialIcons[
                            item.label.toLowerCase().replace(/\s/g, '')
                          ]
                        }
                      />
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <Vr className='-lg:hidden lg:ml-gutter' />
          </div>

          <Hr className='-mx-page-mx lg:hidden' />

          <nav className='grid gap-gutter grid-cols-2 w-full col-span-6'>
            {tree.map((child, index) => (
              <div key={child.id} className='h-full lg:flex'>
                <div className='w-full py-page-my'>
                  <LinkOrExternalLink
                    className={classNames(
                      child.cssClasses,
                      'font-serif text-md font-medium'
                    )}
                    target={child.target}
                    to={child.uri}
                  >
                    {child.label}
                  </LinkOrExternalLink>
                  {renderTree(child.children, {
                    'ul-1': 'body list-none mt-4',
                  })}
                </div>
                {index < tree.length - 1 && (
                  <Vr className='-lg:hidden lg:ml-gutter' />
                )}
              </div>
            ))}
          </nav>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
