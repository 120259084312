//
// ExternalLink
// - properly configures an external (non gatsby) link or form (for buttons)
// - currently opinionated that all button links use the primary variant, may revisit this later
//

import PropTypes from 'prop-types';
import React from 'react';

import Button from 'components/button';

const ExternalLink = ({
  children,
  className = '',
  type = 'a',
  to,
  ...props
}) => {
  if (type === 'a')
    return (
      <a
        className={className}
        href={to}
        {...props}
        rel='noopener noreferrer'
        target='_blank'
      >
        {children}
      </a>
    );
  if (type === 'button')
    return (
      <form
        action={to}
        className={className}
        rel='noopener noreferrer'
        target='_blank'
      >
        <Button label={children} type='submit' {...props} />
      </form>
    );

  return null;
};

ExternalLink.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf(['a', 'button']),
  to: PropTypes.string.isRequired,
};

export default ExternalLink;
