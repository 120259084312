/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { getWindow } from 'ssr-window';

import { useSettings } from 'hooks';

function Seo({ lang = 'en', meta = [], seo = {} }) {
  const w = getWindow();
  const settings = useSettings();
  const defaultTitle = settings?.title;
  const defaultDescription = settings?.description;

  const title = seo?.title || defaultTitle;
  const metaDesc = seo?.metaDesc || defaultDescription;
  const robots = +process.env.GATSBY_IS_PUBLIC
    ? [seo?.metaRobotsNofollow, seo?.metaRobotsNoindex].join(',')
    : 'noindex,nofollow';
  const canonical = seo?.canonical || '';
  const opengraphAuthor = seo?.opengraphAuthor || defaultTitle;
  const opengraphDescription = seo?.opengraphDescription || metaDesc;
  const opengraphImage = seo?.opengraphImage || {};
  const opengraphTitle = seo?.opengraphTitle || title;
  const opengraphType = seo?.opengraphType || '';
  const twitterDescription = seo?.twitterDescription || opengraphDescription;
  const twitterTitle = seo?.twitterTitle || opengraphTitle;

  const metaTags = meta.concat([
    {
      name: `description`,
      content: metaDesc,
    },
    { property: 'og:author', content: opengraphAuthor },
    {
      property: `og:description`,
      content: opengraphDescription,
    },
    {
      property: `og:title`,
      content: opengraphTitle,
    },
    {
      property: `og:type`,
      content: opengraphType,
    },
    {
      name: `twitter:title`,
      content: twitterTitle,
    },
    {
      name: `twitter:description`,
      content: twitterDescription,
    },
    { name: 'robots', content: robots },
  ]);

  if (opengraphImage?.localFile?.childImageSharp?.resize?.src) {
    metaTags.push({
      property: `og:image`,
      content:
        (w.location.origin || '/') +
        opengraphImage.localFile.childImageSharp.resize.src,
    });
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      links={[{ rel: 'canonical', href: canonical }]}
      meta={metaTags}
      title={title}
    />
  );
}

Seo.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  seo: PropTypes.object,
};

export default Seo;

export const query = graphql`
  fragment SeoCareer on WpCareer {
    seo {
      title
      metaDesc
      metaRobotsNofollow
      metaRobotsNoindex
      canonical
      opengraphAuthor
      opengraphDescription
      opengraphTitle
      opengraphType
      twitterDescription
      twitterTitle
      opengraphImage {
        localFile {
          childImageSharp {
            resize(height: 630, width: 1200) {
              src
            }
          }
        }
      }
    }
  }

  fragment SeoPage on WpPage {
    seo {
      title
      metaDesc
      metaRobotsNofollow
      metaRobotsNoindex
      canonical
      opengraphAuthor
      opengraphDescription
      opengraphTitle
      opengraphType
      twitterDescription
      twitterTitle
      opengraphImage {
        localFile {
          childImageSharp {
            resize(height: 630, width: 1200) {
              src
            }
          }
        }
      }
    }
  }

  fragment SeoPost on WpPost {
    seo {
      title
      metaDesc
      metaRobotsNofollow
      metaRobotsNoindex
      canonical
      opengraphAuthor
      opengraphDescription
      opengraphTitle
      opengraphType
      twitterDescription
      twitterTitle
      opengraphImage {
        localFile {
          childImageSharp {
            resize(height: 630, width: 1200) {
              src
            }
          }
        }
      }
    }
  }
`;
