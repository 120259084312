import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Dictionary, Seo, Settings } from 'contexts';

function groupLinksByType(links) {
  const group = {};

  if (!links) return group;

  links.forEach((link) => {
    const { item } = link;
    const type = item.url.includes(':') ? item.url.split(':')[0] : 'https';

    if (!group[type]) group[type] = [];
    group[type].push(item);
  });

  return group;
}

const Wp = ({ children }) => {
  const data = useStaticQuery(graphql`
    query generalSettingAndDictionaryQuery {
      wp {
        generalSettings {
          dateFormat
          description
          startOfWeek
          language
          timezone
          timeFormat
          title
          url
        }
        acfOptionsDictionary {
          dictionary {
            attachment
            bio
            emailAddress
            firstAndLastName
            home
            lastUpdated
            learnMore
            loading
            location
            menu
            message
            phraseAddItem
            phraseAllRightsReserved
            phraseAttachItem
            phraseCloseItem
            phraseNoJobListings
            phraseOpenItem
            phraseOwnersItem
            phrasePleaseEnterAValidItem
            phrasePleaseEnterYourItem
            phraseReadItem
            phraseRemoveItem
            phraseShowMoreItem
            positions
            resume
          }
        }
        acfOptionsApp {
          appSettings {
            offices {
              isPrimary
              name
              googleMap {
                city
                country
                latitude
                longitude
                postCode
                state
                stateShort
                streetAddress
                streetName
                streetNumber
                zoom
              }
              contactLinks {
                item {
                  target
                  title
                  url
                }
              }
            }
            clientPortal {
              target
              title
              url
            }
          }
        }
        acfOptionsCareersSettings {
          contactForm {
            contactForm {
              action
              confirmationMessage
              errorMessage
              formId
              heading
            }
          }
        }
      }
    }
  `);
  const {
    generalSettings: settings,
    acfOptionsApp: { appSettings },
    acfOptionsCareersSettings: careersSettings,
    acfOptionsDictionary: { dictionary },
  } = data.wp;

  appSettings?.offices?.forEach((office) => {
    office.groupedContactLinks = groupLinksByType(office.contactLinks);
  });

  settings.locale = settings.language.replace('_', '-');

  const [seo, setSeo] = useState({});

  return (
    <Seo.Provider value={{ seo, setSeo }}>
      <Dictionary.Provider value={dictionary}>
        <Settings.Provider
          value={{
            ...appSettings,
            ...careersSettings,
            ...settings,
          }}
        >
          {children}
        </Settings.Provider>
      </Dictionary.Provider>
    </Seo.Provider>
  );
};

Wp.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

export default Wp;
