import PropTypes from 'prop-types';
import React from 'react';

import { ReactComponent as LinkedIn } from 'images/social-icon-linkedin.svg';

const components = {
  LinkedIn,
};

const SocialIcon = ({
  platform = 'LinkedIn',
  className = 'fill-green',
  width = 32,
  height = undefined,
  ...props
}) => {
  const Component = components[platform];

  return (
    <Component
      aria-hidden='true'
      className={className}
      height={height}
      width={width}
      {...props}
    />
  );
};

SocialIcon.propTypes = {
  platform: PropTypes.oneOf(['LinkedIn']),
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  props: PropTypes.object,
};

export default SocialIcon;
