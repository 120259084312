import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const MenuAccordionTrigger = ({ isOpen }) => {
  return (
    <div aria-hidden className='w-[40px] h-[2px] relative'>
      <div
        className={classNames(
          'bg-white h-[2px] absolute w-[13px] transition-transform right-[44%]',
          {
            '-rotate-45': isOpen,
            'rotate-45': !isOpen,
          }
        )}
      />
      <div
        className={classNames(
          'bg-white h-[2px] absolute w-[13px] transition-transform left-[44%]',
          {
            'rotate-45': isOpen,
            '-rotate-45': !isOpen,
          }
        )}
      />
    </div>
  );
};

MenuAccordionTrigger.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default MenuAccordionTrigger;
