//
// screens
// - exposes tailwind config theme screens for breakpoint conditionals
//
//

import config from '../../tailwind.config';

const {
  theme: { screens },
} = config;

const cleanedScreens = {};

Object.entries(screens).forEach(([key, value]) => {
  const intValue = parseInt(value);

  if (!Number.isNaN(intValue)) cleanedScreens[key] = intValue;
});

export default cleanedScreens;
