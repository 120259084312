import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

function Container({ children, className = '', ...props }) {
  return (
    <div className={classNames('mx-page-mx', className)} {...props}>
      {children}
    </div>
  );
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Container;
