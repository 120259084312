import React from 'react';

import Layout from 'components/layout';
import Wp from 'components/wp';

export const customLayout = (element, props) => {
  if (!props.data) return element;

  const { wpPage = {}, wpCareer = {}, wpPost = {} } = props.data;

  return (
    <Layout seo={{ ...wpPage?.seo, ...wpCareer?.seo, ...wpPost?.seo }}>
      {element}
    </Layout>
  );
};

export const wpProvider = (element) => <Wp>{element}</Wp>;
