import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import HeadlessButton from 'components/headless-button';

const MenuToggle = ({
  className = '',
  closeLabel,
  handleButton,
  isOpen,
  openLabel,
}) => {
  const onPress = () => {
    if (isOpen && handleButton(false)) handleButton(false);
    else if (!isOpen && handleButton(true)) handleButton(true);
    handleButton(!isOpen);
  };

  return (
    <HeadlessButton
      aria-label={isOpen ? closeLabel : openLabel}
      className={classNames(
        className,
        'w-[40px] h-[24px] relative flex items-center justify-center overflow-hidden'
      )}
      onPress={onPress}
    >
      <div
        className={classNames(
          'absolute top-[1px] inset-x-0 h-[2px] transition-transform transition-duration-200',
          {
            'ease-in-back delay-100 bg-black': !isOpen,
            'ease-out-back -translate-x-full  bg-white': isOpen,
          }
        )}
      />
      <div
        className={classNames(
          'absolute bottom-[1px] inset-x-0 transition-transform transition-duration-200',
          {
            'ease-in-back delay-100 bg-black': !isOpen,
            'ease-out-back translate-x-full delay-50 bg-white': isOpen,
          }
        )}
      >
        <div
          className={classNames('relative w-2/3 h-[2px]', {
            'bg-black': !isOpen,
            'bg-white': isOpen,
          })}
        />
      </div>
      <div
        className={classNames(
          'absolute h-[2px] m-auto transition-transform transition-duration-200 ease-in-back',
          {
            'w-full bg-black': !isOpen,
            'w-[24px] rotate-45 delay-100 bg-white': isOpen,
          }
        )}
      />
      <div
        className={classNames(
          'absolute w-[24px] h-[2px] m-auto transition-transform transition-duration-200 ease-in-back',
          {
            'w-full bg-black': !isOpen,
            'w-[24px] -rotate-45 delay-100 bg-white': isOpen,
          }
        )}
      />
    </HeadlessButton>
  );
};

MenuToggle.propTypes = {
  className: PropTypes.string,
  closeLabel: PropTypes.string.isRequired,
  handleButton: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  openLabel: PropTypes.string.isRequired,
};

export default MenuToggle;
