//
// Button
//

import { useButton } from '@react-aria/button';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import Hr from 'components/hr';
import LinkOrExternalLink from 'components/link-or-external-link';
import Notch from 'components/notch';

const variantClasses = {
  primary: 'button-primary',
  secondary: 'button-secondary',
  link: 'button-link',
};

const Button = ({
  label,
  isLoading = false,
  type = 'button',
  variant = 'primary',
  className = '',
  children,
  to = '',
  target = '_self',
  ...props
}) => {
  const ref = useRef();

  const { buttonProps } = useButton(props, ref);
  const { focusProps, isFocusVisible } = useFocusRing();

  const buttonClassNames = classNames(
    'form-button',
    variantClasses[variant],
    className,
    {
      'is-focus-visible': isFocusVisible,
      'outline-none': !isFocusVisible,
    }
  );

  const content = (
    <div className='w-full'>
      <Hr
        className={classNames('pb-1', {
          'text-black': variant !== 'secondary',
        })}
      />
      {label ?? children}
      {isLoading ? '...' : ''}
      <Notch
        className={
          variant === 'primary' || variant === 'link'
            ? 'fill-green'
            : 'fill-white'
        }
      />
    </div>
  );

  return variant === 'link' ? (
    <LinkOrExternalLink
      className={classNames(
        'form-button inline-flex items-center',
        variantClasses[variant],
        className
      )}
      target={target}
      to={to}
      {...props}
    >
      {content}
    </LinkOrExternalLink>
  ) : (
    <button
      {...mergeProps(buttonProps, focusProps, {
        disabled: buttonProps.disabled || isLoading,
      })}
      ref={ref}
      className={buttonClassNames}
      type={type}
      {...props}
    >
      {content}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
  to: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'link']),
};

// export
export default Button;
