//
// Hooks
//

import { upperFirst } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { getWindow } from 'ssr-window';

import { Dictionary, Seo, Settings } from 'contexts';
import { removeIndex } from 'utils/array';
import screens from 'utils/screens';

const w = getWindow();

/**
 * Add classes to the body tag.
 *
 * @export
 * @param {...string} classNames
 */
export const useBodyClass = (...classNames) => {
  useEffect(() => {
    // mount
    w.document.body.classList.add(...classNames);

    // unmount
    return () => w.document.body.classList.remove(...classNames);
  });
};

/**
 * Use WordPress dictionary.
 *
 * @export
 * @return {Object} An object with all the WordPress dictionary.
 */
export function useDictionary() {
  const dictionary = useContext(Dictionary);

  return dictionary;
}

/**
 * Handle when clicking outside an element .
 * @param {  React.MutableRefObject<undefined> } ref The element to listen to.
 * @param {Function} handler The function to call when clicking.
 * @export
 */
export function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    w.document.addEventListener('mousedown', listener);
    w.document.addEventListener('touchstart', listener);

    return () => {
      w.document.removeEventListener('mousedown', listener);
      w.document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}

/**
 * Detects mobile OS.
 *
 * @export
 * @return {Boolean, Boolean} isIOS, isAndroid.
 */
export const useOS = () => {
  const [isIOS, setIsIOS] = useState(false);
  const [isAndroid, setIsAndroid] = useState(false);

  useEffect(() => {
    const userAgent = w.navigator.userAgent || w.navigator.vendor || w.opera;

    if (/android/i.test(userAgent)) {
      setIsAndroid(true);
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !w.MSStream) {
      setIsIOS(true);
    }
  }, []);

  return { isIOS, isAndroid };
};

let pageIndexKeys = [];

/**
 * Register, retrieve or remove an index of elements on the current page.
 *
 * @export
 * @return {Function, Function, Function} The add, delete and get index methods.
 */
export const usePageIndex = () => {
  const addIndex = (key) => {
    if (pageIndexKeys.includes(key)) return;
    pageIndexKeys.push(key);
  };

  const deleteIndex = (key) => {
    const index = getIndex(key);

    if (index < 0) return;
    pageIndexKeys = removeIndex(pageIndexKeys, index);
  };

  const getIndex = (key) => {
    return pageIndexKeys.indexOf(key);
  };

  return { addIndex, deleteIndex, getIndex };
};

/**
 * Use page SEO properties.
 *
 * @export
 * @return {Object} An object with all the page SEO properties.
 */
export function useSeo() {
  const seo = useContext(Seo);

  return seo;
}

/**
 * Use WordPress settings.
 *
 * @export
 * @return {Object} An object with all the WordPress settings.
 */
export function useSettings() {
  const settings = useContext(Settings);

  return settings;
}

/**
 * @typedef {Object} WindowSize
 * @property {Number} width - The window width.
 * @property {Number} height - The window height.
 * @property {Boolean} hasSize* - If the window is bigger than tailwind screen.x (min).
 * @property {Boolean} isSize* - If the window is bigger than tailwind screen.x, but smaller than the next bigger sized screen (min/max).
 */

/**
 * Get the window size object needed for the useWindowSize hook.
 *
 * @return {WindowSize} The window size object.
 */
const getWindowSize = () => {
  // base
  const windowSize = {
    width: w.innerWidth,
    height: w.innerHeight,
  };
  // sizes (must be listed smallest to biggest)
  const sizes = ['xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl'];

  // add size props
  sizes.forEach((size, index) => {
    const sizeName = upperFirst(size);

    // has size (check min width)
    windowSize[`hasSize${sizeName}`] = windowSize.width >= screens[size];
    // is size (check min/max width)
    windowSize[`isSize${sizeName}`] =
      windowSize[`hasSize${sizeName}`] &&
      (index < sizes.length - 1
        ? windowSize.width < screens[sizes[index + 1]]
        : true);
  });

  // return
  return windowSize;
};

/**
 * Use window size.
 *
 * @export
 * @return {WindowSize} The window size object.
 */
export const useWindowSize = () => {
  // initialize state with undefined width/height so server and client renders match
  const undefinedWindowSize = getWindowSize();

  Object.entries(undefinedWindowSize).forEach(([key]) => {
    undefinedWindowSize[key] = undefined;
  });
  const [windowSize, setWindowSize] = useState(undefinedWindowSize);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    setWindowSize(getWindowSize());
    w.addEventListener('resize', handleResize);

    return () => w.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};
