const minWidthScreens = {
  xs: '375px',
  sm: '600px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  '2xl': '1536px',
  '3xl': '1920px',
};

// max widths, subtract 1px from min width and create max width config object
const maxWidthScreens = {};

Object.entries(minWidthScreens).forEach(([name, size]) => {
  maxWidthScreens[`-${name}`] = { max: `${parseInt(size) - 1}px` };
});

/** @type {import('tailwindcss').Config} */
module.exports = {
  mode: 'jit',
  content: ['./src/**/*.{js,jsx,ts,tsx}', './.storybook/**/*.{js,jsx,ts,tsx}'],
  theme: {
    // extends first
    extend: {
      animation: {
        'fade-in': 'fade-in 150ms ease-out',
      },
      keyframes: {
        'fade-in': {
          '0%': { opacity: 0 },
          '100%': { opacity: 1 },
        },
      },
      letterSpacing: {
        xl: '0.32em',
      },
      maxWidth: {
        'xs-ch': '16ch',
        'sm-ch': '24ch',
        'md-ch': '32ch',
        'lg-ch': '48ch',
        'xl-ch': '64ch',
        '4/6': 'calc(100% / 6 * 4)',
      },
      spacing: {
        channel: '16px',
        gutter: 'var(--gutter)',
        '-page-mx': 'var(--negative-page-mx)',
        'page-mx': 'var(--page-mx)',
        'page-my': 'var(--page-my)',
        'negative-m': 'var(--negative-m)',
      },
      transitionDelay: {
        10: '10ms',
        20: '20ms',
        30: '30ms',
        40: '40ms',
        50: '50ms',
      },
      transitionTimingFunction: {
        'in-expo': 'cubic-bezier(0.95, 0.05, 0.795, 0.035)',
        'out-expo': 'cubic-bezier(0.19, 1, 0.22, 1)',
        'in-back': 'cubic-bezier(0.36, 0, 0.66, -0.56)',
        'out-back': 'cubic-bezier(0.34, 1.56, 0.64, 1)',
      },
      translate: {
        'xy-full': 'translateX(100%) translateY(100%)',
      },
      zIndex: {
        '-1': '-1',
      },
    },
    // then overwrites
    colors: {
      transparent: 'transparent',
      black: '#000000',
      green: {
        DEFAULT: '#225C4F',
        light: '#49A934',
      },
      white: {
        DEFAULT: '#FFFFFF',
        off: '#F3F3F3',
      },
      gray: {
        DEFAULT: '#231F20',
        light: '#808080',
      },
      overlay: 'rgba(0, 0, 0, .3)',
      red: '#FF0000',
    },
    fontFamily: {
      sans: ['"Founders Grotesk"', 'sans-serif'],
      serif: ['"Tiempos Headline"', 'serif'],
    },
    fontSize: {
      xs: 'var(--font-size-xs)',
      sm: 'var(--font-size-sm)',
      md: 'var(--font-size-md)',
      lg: 'var(--font-size-lg)',
      xl: 'var(--font-size-xl)',
      '2xl': 'var(--font-size-2xl)',
      '3xl': 'var(--font-size-3xl)',
      '4xl': 'var(--font-size-4xl)',
    },
    fontWeight: {
      normal: '400',
      medium: '500',
    },
    lineHeight: {
      none: '1',
      tight: '0.9em',
      normal: '1.2em',
      open: '1.5em',
      'x-open': '2em',
    },
    screens: {
      ...minWidthScreens,
      ...maxWidthScreens,
    },
  },
  plugins: [],
};
