import classNames from 'classnames';
import { Link } from 'gatsby';
import upperFirst from 'lodash/upperFirst';
import React, { useEffect, useRef, useState } from 'react';
import { useSpring, animated } from 'react-spring';

import Container from 'components/container';
import Logo from 'components/logo';
import MenuToggle from 'components/menu-toggle';
import PrimaryNav from 'components/primary-nav';
import { useDictionary, useWindowSize } from 'hooks';
import { medium as defaultSpring } from 'springs';
import template from 'utils/template';

const Header = () => {
  const { home } = useDictionary();
  const { menu, phraseCloseItem, phraseOpenItem } = useDictionary();
  const windowSize = useWindowSize();

  const [isAnimating, setIsAnimating] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [navHeight, setNavHeight] = useState(0);

  const ref = useRef();

  const animatedStyle = useSpring({
    config: defaultSpring,
    height: isOpen ? '100%' : '0%',
    onStart: () => setIsAnimating(true),
    onRest: () => setIsAnimating(false),
  });

  // block page scroll when nav is opened
  useEffect(() => {
    isOpen && document.body.classList.add('overflow-y-hidden'); //eslint-disable-line

    return () => document.body.classList.remove('overflow-y-hidden'); //eslint-disable-line
  }, [isOpen]);

  // set a non-fixed element's height to match the main nav to shim it
  useEffect(() => {
    setNavHeight(ref.current.scrollHeight);
  }, [ref, windowSize.width]);

  return (
    <>
      <header className='fixed w-full z-40'>
        <nav ref={ref} className='inset-x-0 bg-white-off'>
          <div className='absolute w-full h-screen pointer-events-none'>
            <animated.div
              className={classNames('absolute inset-x-0 bg-green', {
                'top-0': isOpen,
                'bottom-0': !isOpen,
              })}
              style={animatedStyle}
            />
          </div>
          <Container className='relative'>
            <div className='flex flex-row items-center justify-between py-page-my'>
              <Link
                aria-label={upperFirst(home)}
                to='/'
                onClick={() => setIsOpen(false)}
              >
                <Logo
                  className={classNames('fill-green transition-colors', {
                    'fill-white': isOpen,
                  })}
                />
              </Link>
              <MenuToggle
                closeLabel={template(phraseCloseItem)({ item: menu })}
                handleButton={() => setIsOpen(!isOpen)}
                isOpen={isOpen}
                openLabel={template(phraseOpenItem)({ item: menu })}
              />
            </div>
          </Container>
          <PrimaryNav
            isAnimating={isAnimating}
            isOpen={isOpen}
            navHeight={navHeight}
            setIsOpen={setIsOpen}
          />
        </nav>
      </header>
      <div style={{ height: `${navHeight}px` }} />
    </>
  );
};

Header.propTypes = {};

export default Header;
