import classNames from 'classnames';
import defaults from 'lodash/defaults';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import { useSpring, animated } from 'react-spring';

import { default as defaultSpring } from 'springs';

const Vr = ({ springConfig = {}, className = '', ...props }) => {
  const ref = useRef();
  const { inViewport } = useInViewport(ref, props);
  const animStyles = useSpring({
    config: defaults({}, springConfig, defaultSpring),
    from: { height: '0%' },
    to: inViewport && { height: '100%' },
    delay: 200,
  });

  return (
    <animated.hr
      ref={ref}
      className={classNames('w-[1px] h-full border-r opacity-50', className)}
      style={animStyles}
      {...props}
    />
  );
};

Vr.propTypes = {
  springConfig: PropTypes.object,
  className: PropTypes.string,
  props: PropTypes.object,
};

export default Vr;
