import * as Accordion from '@radix-ui/react-accordion';
import classNames from 'classnames';
import { graphql, Link, useStaticQuery } from 'gatsby';
import upperFirst from 'lodash/upperFirst';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { FocusScope } from 'react-aria';

import Button from 'components/button';
import Container from 'components/container';
import HeadlessButton from 'components/headless-button';
import Hr from 'components/hr';
import LinkOrExternalLink from 'components/link-or-external-link';
import Logo from 'components/logo';
import MenuAccordionTrigger from 'components/menu-accordion-trigger';
import Vr from 'components/vr';
import { useDictionary, useSettings } from 'hooks';
import { toTree } from 'utils/menus';
import template from 'utils/template';

const PrimaryNav = ({ isAnimating, isOpen, navHeight, setIsOpen }) => {
  const { home, menu, phraseCloseItem } = useDictionary();
  const { clientPortal } = useSettings();

  const [accordionValue, setAccordionValue] = useState(null);
  const [isEntered, setIsEntered] = useState(false);

  const { wpMenu } = useStaticQuery(
    graphql`
      query {
        wpMenu(locations: { eq: PRIMARY_NAV }) {
          databaseId
          menuItems {
            nodes {
              cssClasses
              title
              target
              uri
              order
              databaseId
              parentDatabaseId
              label
            }
          }
        }
      }
    `
  );

  const navTree = toTree(wpMenu?.menuItems?.nodes);

  const renderChildNavItem = (childNavItem, index) => {
    return (
      <React.Fragment key={`${childNavItem.label}-${index}`}>
        <LinkOrExternalLink
          className='text-white text-lg block focus:ring-2'
          target={childNavItem.target}
          to={childNavItem.uri}
          onClick={() => setIsOpen(false)}
        >
          {childNavItem.label}
        </LinkOrExternalLink>
        {childNavItem.children.length
          ? childNavItem.children.map(renderChildNavItem)
          : null}
      </React.Fragment>
    );
  };

  const onKeydown = useCallback((event) => {
    if (event.key === 'Escape') setIsOpen(false);
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', onKeydown);

    return () => document.removeEventListener('keydown', onKeydown);
  }, []);

  useEffect(() => {
    if (isAnimating) setIsEntered(true);
    if (!isOpen) setIsEntered(false);
  }, [isAnimating, isOpen]);

  useEffect(() => {
    setAccordionValue(false);
  }, [isOpen]);

  return (
    <div
      className={classNames('absolute top-0 w-full h-screen flex flex-col', {
        'invisible pointer-events-none': !isOpen,
      })}
    >
      {isOpen && (
        <FocusScope autoFocus contain restoreFocus>
          <div style={{ height: navHeight }} />
          <Container className='lg:hidden'>
            <Hr className='text-white mb-5' />
          </Container>

          <Container className='flex-1 gap-gutter grid grid-cols-1 lg:grid-cols-4'>
            <div
              className={classNames(
                'lg:order-2 lg:col-start-4 transition-opacity opacity-0',
                {
                  'delay-100 opacity-100': isEntered,
                }
              )}
            >
              <Accordion.Root
                collapsible
                className='ml-gutter'
                type='single'
                onValueChange={(value) => {
                  setAccordionValue(value);
                }}
              >
                {navTree.map((navItem, index) => (
                  <Accordion.Item
                    key={`${navItem.label}-${index}`}
                    className='mb-4'
                    value={`item-${index}`}
                  >
                    <Accordion.Trigger
                      className='appearance-none cursor-pointer w-full'
                      tabIndex={navItem.children.length ? 0 : -1}
                    >
                      <div
                        className={classNames({
                          'flex justify-between items-center':
                            navItem.children.length,
                        })}
                      >
                        <div className='text-white text-2xl font-serif w-full text-left'>
                          {navItem.children.length ? (
                            navItem.label
                          ) : (
                            <LinkOrExternalLink
                              className='block w-full'
                              target={navItem.target}
                              to={navItem.uri}
                              onClick={() => setIsOpen(false)}
                            >
                              {navItem.label}
                            </LinkOrExternalLink>
                          )}
                        </div>
                        {navItem.children.length ? (
                          <MenuAccordionTrigger
                            isOpen={accordionValue === `item-${index}`}
                          />
                        ) : null}
                      </div>
                    </Accordion.Trigger>
                    {navItem.children.length ? (
                      <Accordion.Content>
                        {navItem.children.map(renderChildNavItem)}
                      </Accordion.Content>
                    ) : null}
                  </Accordion.Item>
                ))}
              </Accordion.Root>
            </div>
            <div className='mb-page-my flex flex-col justify-end lg:order-1'>
              {clientPortal && (
                <Button
                  className={classNames(
                    'mt-page-my max-w-full transition-opacity opacity-0',
                    {
                      'delay-700 opacity-100': isEntered,
                    }
                  )}
                  variant='secondary'
                >
                  <LinkOrExternalLink
                    target={clientPortal?.target}
                    to={clientPortal?.url}
                  >
                    {clientPortal?.title}
                  </LinkOrExternalLink>
                </Button>
              )}
            </div>
          </Container>

          <div className='-lg:hidden absolute h-full w-full pointer-events-none'>
            <Container className='h-full grid grid-cols-4'>
              <Vr className='text-white col-start-4' />
            </Container>
          </div>

          <div className='absolute top-0 inset-x-0'>
            <Container
              className='flex items-center justify-between'
              style={{ height: navHeight }}
            >
              <Link
                aria-label={upperFirst(home)}
                to='/'
                onClick={() => setIsOpen(false)}
              >
                <Logo
                  className={classNames('fill-green transition-colors', {
                    'fill-white': isOpen,
                  })}
                />
              </Link>
              <HeadlessButton
                aria-label={template(phraseCloseItem)({ item: menu })}
                className='w-[40px] h-[24px]'
                onPress={() => setIsOpen(false)}
              />
            </Container>
          </div>
        </FocusScope>
      )}
    </div>
  );
};

PrimaryNav.propTypes = {
  navHeight: PropTypes.number.isRequired,
  isAnimating: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default PrimaryNav;
