import { useButton } from '@react-aria/button';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

const HeadlessButton = ({
  type = 'button',
  className = '',
  children,
  ...props
}) => {
  const ref = useRef();

  const { buttonProps } = useButton(props, ref);
  const { focusProps, isFocusVisible } = useFocusRing();

  return (
    <button
      ref={ref}
      className={classNames(className, {
        'is-focus-visible': isFocusVisible,
        'outline-none': !isFocusVisible,
      })}
      type={type}
      {...mergeProps(buttonProps, focusProps, {
        disabled: buttonProps.disabled,
      })}
      title={props.title || null}
    >
      {children}
    </button>
  );
};

HeadlessButton.propTypes = {
  type: PropTypes.oneOf(['submit', 'button', 'reset']),
  className: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string,
  props: PropTypes.object,
};

export default HeadlessButton;
