import PropTypes from 'prop-types';
import React from 'react';

import { useSettings } from 'hooks';
import { ReactComponent as SVG } from 'images/logo.svg';

const Logo = ({
  className = 'fill-green',
  width = 173,
  height = undefined,
  ...props
}) => {
  const { title } = useSettings();

  return (
    <SVG
      aria-label={title}
      className={className}
      height={height}
      width={width}
      {...props}
    />
  );
};

Logo.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  props: PropTypes.object,
};

export default Logo;
