import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import ExternalLink from 'components/external-link';

const LinkOrExternalLink = ({ target, to, ...props }) => {
  const Component =
    !to.startsWith('/') || target === 'blank' ? ExternalLink : Link;

  return <Component to={to} {...props} />;
};

LinkOrExternalLink.propTypes = {
  target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
  to: PropTypes.string.isRequired,
};

export default LinkOrExternalLink;
