import PropTypes from 'prop-types';
import React from 'react';

import { ReactComponent as SVG } from 'images/notch.svg';

const Notch = ({
  className = 'fill-green',
  width = 10,
  height = undefined,
  ...props
}) => {
  return <SVG className={className} height={height} width={width} {...props} />;
};

Notch.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  props: PropTypes.object,
};

export default Notch;
