/**
 * Return the first property of an object that exists from a list of props.
 *
 * @export
 * @param {Object} object - The object to check.
 * @param {...String} props - The properties to check.
 * @return {*} The object's first property that exists' value or undefined if no matches.
 */
export function getFirstPropertyExists(object, ...props) {
  const prop = props.find((prop) => hasOwnProperty(object, prop));

  if (prop) return object[prop];

  return undefined;
}

/**
 * Check if an object has its own specified property.
 *
 * @export
 * @param {Object} object - The object to check.
 * @param {String} prop - The property to check.
 * @return {Boolean}
 */
export function hasOwnProperty(object, prop) {
  return Object.prototype.hasOwnProperty.call(object, prop);
}
