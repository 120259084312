import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import Footer from 'components/footer';
import Header from 'components/header';
import Seo from 'components/seo';
import { useSettings, useSeo } from 'hooks';

const Layout = ({ children, seo: pageSeo = {} }) => {
  const settings = useSettings();
  const { seo, setSeo } = useSeo();

  useEffect(() => {
    setSeo(pageSeo);
  }, [pageSeo]); //eslint-disable-line

  return (
    <>
      <Seo lang={settings.locale} seo={seo} />
      <div className='flex flex-col min-h-screen'>
        <div className='flex-grow'>
          <Header />
          <main>{children}</main>
        </div>
        <Footer className='flex justify-center' />
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  seo: PropTypes.object,
};

export default Layout;
