import { default as buildClassNames } from 'classnames';
import React from 'react';

import LinkOrExternalLink from 'components/link-or-external-link';
import { getFirstPropertyExists } from 'utils/object';

/**
 * Transform a flat array into a tree
 *
 * @export
 * @param {Array} array - An array of objects with at least the following keys: `databaseId`, `parentDatabaseId` and `order`.
 * @param {Number} [parentDatabaseId=0] - The parent ID of the current level.
 * @return {Array} The array in tree format, grouping the children under the `children` key.
 */
export const toTree = (array, parentDatabaseId = 0) =>
  array
    .filter((item) => item.parentDatabaseId === parentDatabaseId)
    .map((child) => ({
      ...child,
      children: toTree(array, child.databaseId).sort((a, b) =>
        a.order > b.order ? 1 : -1
      ),
    }))
    .sort((a, b) => (a.order > b.order ? 1 : -1));

/**
 * Render a tree array
 *
 * @export
 * @param {Array} tree - An array with the objects nested through the `children` key.
 * @param {Object} classNames - An object containing the class names to add to the menu.
 * @return {HTML} The rendered tree in HTML format.
 *
 * ClassNames.ul, .li, or .a will be used for the correspoding elements if no
 * depth className is provided.
 *
 * To set a depth class name use classNames.{element}-{depth}.
 * For example, set classNames['ul-2'] to style the 2nd level list.
 *
 TODO see if it is more convenient to leave this function for the footer or just 
 follow the new primary nav pattern.
 */
export const renderTree = (
  tree,
  classNames = {
    ul: 'body list-disc list-outside ml-[1em] mb-1 last:mb-0',
    li: 'ml-[1em]',
    a: '',
  },
  depth = 1
) =>
  tree.map((item) => {
    const ulClassNames = getFirstPropertyExists(
      classNames,
      `ul-${depth}`,
      'ul'
    );
    const liClassNames = getFirstPropertyExists(
      classNames,
      `li-${depth}`,
      'li'
    );
    const aClassNames = buildClassNames(
      item.cssClasses,
      getFirstPropertyExists(classNames, `a-${depth}`, 'a')
    );

    return (
      <ul key={item.databaseId} className={ulClassNames}>
        <li className={liClassNames}>
          <LinkOrExternalLink
            className={aClassNames}
            target={item.target}
            to={item.uri}
          >
            {item.label}
          </LinkOrExternalLink>
        </li>
        {item.children && renderTree(item.children, classNames, depth + 1)}
      </ul>
    );
  });
