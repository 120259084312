/**
 * Remove an index from an array.
 *
 * @param {Array} arr - The array to remove from.
 * @param {Int} index - The index to remove.
 * @return {Array} The array with the index removed.
 */
export const removeIndex = (arr, index) => {
  if (index === 0) return [...arr.slice(1)];

  return [...arr.slice(0, index - 1), ...arr.slice(index)];
};
